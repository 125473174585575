import * as React from 'react';
import { sectionChangedClass } from '../../../AppConstants';
import { IExternalDocumentChunksData, IExternalDocumentNumberingData, IOrderedSection, ISection } from '../../duck/Models';
import { DocumentDeletedNotification, NoChunksNotification, SectionDeletedNotification, UntaggableDocumentNotification, UpdateNotification } from './MarkupComponents';

import * as classNames from 'classnames';
import './SectionSelector.scss';
import i18n from "../../../i18n";
import { Tooltip } from 'react-tooltip';

export interface ISectionSelectorProps {
  sections: ISection[];
  sectionDeleted: boolean;
  panvivaDocumentDeleted: boolean;
  fetchingPanvivaDocument: boolean;
  validationErrorText?: string;
  name: string;
  disabledContentCuration: boolean;
  enableAIAssistant: boolean;
  fetchContentCuration: Function;
  onSelectSections: (sections: IOrderedSection[]) => any;
  undoSections: ISection[][];
  undoSectionAction: Function;
  navigatedPageStateForSections: boolean;
  newExternalDocumentChunks: IExternalDocumentChunksData[];
  currentExternalDocumentChunks: IExternalDocumentChunksData[];
  isExternal: boolean;
  enableAIAssistantForChunks: boolean;
  documentNumbering: IExternalDocumentNumberingData[];
  onChunkChange: (sections: Api.IChunkViewModel[]) => any;
  updateNotification: boolean;
  setChunks: Function;
}

type ISectionItemProps = { section: IOrderedSection } & { onToggleSelection: (e: any, selectedItem: IOrderedSection) => any };

interface ISectionSelectorState {
  showCurrent: boolean;
  showNew: boolean;
}

export class SectionSelector extends React.Component<ISectionSelectorProps, ISectionSelectorState> {
  constructor(props: ISectionSelectorProps) {
    super(props);
    this.state = {
      showCurrent: true,
      showNew: true,
    };

    this.onToggleItemSelection = this.onToggleItemSelection.bind(this);
    this.toggleCurrentCollapse = this.toggleCurrentCollapse.bind(this);
    this.toggleNewCollapse = this.toggleNewCollapse.bind(this);
    this.toggleCurrentChunkSelection = this.toggleCurrentChunkSelection.bind(this);
    this.toggleNewChunkSelection = this.toggleNewChunkSelection.bind(this);
  }

  public render() {

    if (this.props.panvivaDocumentDeleted && !this.props.isExternal) {
      return (
        <div className="section-selector-container">
          <div className="section-selector">
            <DocumentDeletedNotification />
          </div>
        </div>
      )
    }
    if (!this.props.fetchingPanvivaDocument && this.props.sections.length <= 0 && !this.props.isExternal) {
      return (
        <div className="section-selector-container">
          <div className="section-selector">
            <UntaggableDocumentNotification />
          </div>
        </div>
      )
    }
    if (this.props.currentExternalDocumentChunks.length <= 0 && this.props.newExternalDocumentChunks.length <= 0 && this.props.isExternal) {
      return (
        <div className="section-selector-container">
          <div className="section-selector">
            <NoChunksNotification />
          </div>
        </div>
      )
    }
    return (
      <div className="section-selector-container" id={this.props.name}>
        {this.props.enableAIAssistant || this.props.enableAIAssistantForChunks ?
          <>
            <span className="disclaimer-text msg-tooltip-icon">
              <i className="icon icon-info-circle margin-right-2" />
              <span className="padding-top-1">{i18n.t("manage.tooltips.documentContentDisclaimer")}</span>
            </span>
            <div className="curation-block">
              <div className="dropdown">
                <div className="">
                  {
                    this.props.undoSections.length > 1 && !this.props.navigatedPageStateForSections ?
                      <img className="EnabledUndoIcon" src="/images/undo.svg" alt="undo icon" onClick={() => this.props.undoSectionAction()} /> :
                      <img className="DisabledUndoIcon" src="/images/disabledUndo.svg" alt="undo icon" />
                  }
                  {
                    this.props.disabledContentCuration ?
                      <>
                        <div className="wandicon" data-tooltip-id="section-selector-tooltip" data-tooltip-content={i18n.t("manage.tooltips.wandiconTooltipDocumentContent")}>
                          <span className={"msg-tooltip-icon"}>
                            <button className={classNames("padding-3 font-12", "disabled-button")}
                              type="button" id="dropdownMenuButton"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img className="greyWandicon" src="/images/greyWandicon.svg" alt="grey wand icon" />
                              AI KNOWLEDGE ASSISTANT
                              <img className="greyDownArrow" src="/images/greyDownArrow.svg" alt="grey down arrow icon" />
                            </button>
                          </span>
                        </div>
                        <Tooltip id="section-selector-tooltip" place={"right"} variant={"info"} className={"cc-tooltip"}
                          openOnClick={true} />
                      </> :
                      <button className={classNames("btn padding-3 font-12 shadow-active", "btn-outline-secondary")}
                        type="button" id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img className="enabled-wandicon" src="/images/wandicon.svg" alt="wand icon" />
                        AI KNOWLEDGE ASSISTANT
                        <img className="downArrow" src="/images/downArrow.svg" alt="down arrow icon" />
                      </button>
                  }
                  <div className="dropdown-menu btn padding-3 btn-outline-secondary font-12" aria-labelledby="dropdownMenuButton">
                    <div onClick={() => this.props.fetchContentCuration()}> Curate content</div>
                  </div>
                </div>
              </div>
            </div></> : null}

        <div className={classNames("section-selector", { "is-invalid": this.props.validationErrorText })}>
          {this.props.sectionDeleted && !this.props.isExternal && <SectionDeletedNotification />}
          <div className="scroll-panel">
            <ul>
              {
                this.props.isExternal ?
                  <>
                    {
                      <div>
                        <div className="snippet-accordion">
                          <div className="snippet-card">
                            <div className="snippet-card-header padding-top-8" id="current-snippet-heading">
                              <h5 className="mb-0">
                                <div className="header-text current-snippet-color">
                                  CURRENTLY SELECTED SNIPPETS
                                </div>
                                <img className="downArrow" src="/images/downArrow.svg" alt="down arrow icon" onClick={this.toggleCurrentCollapse} />
                              </h5>
                            </div>
                            {
                              this.props.updateNotification ? <UpdateNotification /> : null
                            }

                            <div id="current-snippet" className={`collapse${this.state.showCurrent ? ' show' : ''}`} aria-labelledby="current-snippet-heading" >
                              <div className="snippet-card-body scrollbar">
                                {this.props.currentExternalDocumentChunks.length ?
                                  this.props.currentExternalDocumentChunks.map((a: IExternalDocumentChunksData, index: number) =>
                                    <li className={`custom-control custom-checkbox  ${a.checked ? "checked" : ""}`} key={index}>
                                      {!a.isDeleted ?
                                        <> <input className="custom-control-input" type="checkbox" id={`chunk_${index}`} checked={a.checked} onChange={(e) => this.toggleCurrentChunkSelection(e, a)} />
                                          <label className={classNames("custom-control-label external-label-padding")} htmlFor={`chunk_${index}`}>
                                            {a.content}
                                          </label></> :
                                        <>
                                          <input className="custom-control-input" type="checkbox" id={`chunk_${index}`} checked={false} disabled />
                                          <label className={classNames("custom-control-label external-label-padding backgound-color-banner")} htmlFor={`chunk_${index}`}>
                                            {i18n.t("manage.documentSelection.deletedChunksMessage")}
                                          </label>
                                        </>}
                                      <>
                                        {this.props.documentNumbering.map((b, index) =>
                                          a.sourceDocumentName == b.documentName ? <img className="chunk-icon" key={index} src={`/images/Chunk-blue-${b.number}.svg`} alt="chunk icon" onClick={() => window.open(b.documentUrl)} />
                                            : null)}
                                      </>
                                    </li>
                                  ) :
                                  <div>
                                    <p className="notification-message margin-top-0">
                                      {i18n.t("manage.documentSelection.noRelevantChunksMessage")}
                                    </p>
                                  </div>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="snippet-accordion">
                          <div className="snippet-card">
                            <div className="snippet-card-header border-top-1 padding-top-16 " id="new-snippet-heading">
                              <h5 className="mb-0">
                                <div className="header-text ">
                                  NEWLY SELECTED SNIPPETS
                                </div>
                                <img className="downArrow" src="/images/greyDownArrow.svg" alt="down arrow icon" onClick={this.toggleNewCollapse} />
                              </h5>
                            </div>
                            <div id="new-nippet" className={`collapse${this.state.showNew ? ' show' : ''}`} aria-labelledby="new-snippet-heading" >
                              <div className="snippet-card-body scrollbar">
                                {this.props.newExternalDocumentChunks.length ?
                                  this.props.newExternalDocumentChunks.map((a: IExternalDocumentChunksData, index: number) =>
                                    <li className={`custom-control custom-checkbox  ${a.checked ? "checked" : ""}`} key={index}>
                                      <input className="custom-control-input" type="checkbox" id={`new_chunk_${index}`} checked={a.checked} onChange={(e) => this.toggleNewChunkSelection(e, a)} />
                                      <label className={classNames("custom-control-label external-label-padding")} htmlFor={`new_chunk_${index}`}>
                                        {a.content}
                                      </label>
                                      <>
                                        {this.props.documentNumbering.map((b, index) =>
                                          a.sourceDocumentName == b.documentName ? <img className="chunk-icon width-17" key={index} src={`/images/Chunk-grey-${b.number}.svg`} alt="grey chunk icon" onClick={() => window.open(b.documentUrl)} />
                                            : null)}
                                      </>
                                    </li>
                                  ) : <div>
                                    <p className="notification-message margin-top-0">
                                      {i18n.t("manage.documentSelection.noRelevantChunksMessage")}
                                    </p>
                                  </div>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                  :
                  <>
                    {this.props.sections.map((section, index) => (<SectionListItem section={{ ...section, index }} key={section.sectionId} onToggleSelection={this.onToggleItemSelection} />))}
                  </>
              }
            </ul>
          </div>
        </div>
        {this.props.validationErrorText && <div className="invalid-feedback">{this.props.validationErrorText}</div>}
      </div>
    );
  }

  private toggleCurrentChunkSelection(event: any, selectedChunk: IExternalDocumentChunksData) {
    let chunks: IExternalDocumentChunksData[] = JSON.parse(JSON.stringify(this.props.currentExternalDocumentChunks));
    let taggedChunks: Api.IChunkViewModel[] = [];

    let checkedChunk = chunks.findIndex((a) => a.id == selectedChunk.id);
    if (checkedChunk >= 0) {
      chunks[checkedChunk].checked = event.target.checked;
    }

    chunks.forEach((a) => {
      let b: Api.IChunkViewModel = {
        ChunkId: a.id,
        DocumentId: a.sourceDocumentId,
        Hash: ""
      };

      if (a.checked) {
        taggedChunks.push(b);
      }
    })

    if (this.props.newExternalDocumentChunks.length) {
      this.props.newExternalDocumentChunks.map((a => {
        taggedChunks.push({
          ChunkId: a.id,
          DocumentId: a.sourceDocumentId,
          Hash: ""
        })
      }))
    }
    this.props.onChunkChange(taggedChunks);
    this.props.setChunks(chunks, true);
  }

  private toggleNewChunkSelection(event: any, selectedChunk: IExternalDocumentChunksData) {
    let chunks: IExternalDocumentChunksData[] = JSON.parse(JSON.stringify(this.props.newExternalDocumentChunks));
    let taggedChunks: Api.IChunkViewModel[] = [];

    let checkedChunk = chunks.findIndex((a) => a.id == selectedChunk.id);
    if (checkedChunk >= 0) {
      chunks[checkedChunk].checked = event.target.checked;
    }

    chunks.forEach((a) => {
      let b: Api.IChunkViewModel = {
        ChunkId: a.id,
        DocumentId: a.sourceDocumentId,
        Hash: ""
      };

      if (a.checked) {
        taggedChunks.push(b);
      }
    })

    if (this.props.currentExternalDocumentChunks.length) {
      this.props.currentExternalDocumentChunks.map((a => {
        taggedChunks.push({
          ChunkId: a.id,
          DocumentId: a.sourceDocumentId,
          Hash: ""
        })
      }))
    }


    this.props.onChunkChange(taggedChunks);
    this.props.setChunks(chunks, false);
  }

  private getSelections(sections: ISection[]): IOrderedSection[] {
    return sections.map((item, index) => ({ ...item, index })).filter((item) => item.checked);
  }

  private onToggleItemSelection(event: any, selectedItem: IOrderedSection) {
    const newSelections = this.getSelections(this.props.sections).filter(item => item.sectionId !== selectedItem.sectionId);

    if (event.target.checked) {
      newSelections.push({ ...selectedItem, checked: true });
    }

    const sortedSelections = newSelections.sort((sectionA, sectionB) => sectionA.index! - sectionB.index!);
    this.props.onSelectSections(sortedSelections);
  }

  private toggleCurrentCollapse() {
    this.setState((state, props) => {
      return {
        showCurrent: !state.showCurrent
      }
    })
  }

  private toggleNewCollapse() {
    this.setState((state, props) => {
      return {
        showNew: !state.showNew
      }
    })
  }
}

export class SectionListItem extends React.Component<ISectionItemProps> {
  constructor(props: ISectionItemProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  public render() {
    return (
      <li className={`custom-control custom-checkbox ${this.props.section.checked ? "checked" : ""}`}>
        <input className="custom-control-input" type="checkbox" value={this.props.section.sectionId} id={`section_check_${this.props.section.sectionId}`} checked={this.props.section.checked} onChange={this.onChange} />
        <label className={classNames("custom-control-label", { [sectionChangedClass]: this.props.section.hasChanges })} htmlFor={`section_check_${this.props.section.sectionId}`} dangerouslySetInnerHTML={this.getFormattedMarkup(this.props.section.richContent!)} />
      </li>
    );
  }
  private onChange(event: any) {
    this.props.onToggleSelection(event, this.props.section);
  }

  private getFormattedMarkup(rawText: string): { __html: string } {
    return {
      __html: rawText
    };
  }
}


