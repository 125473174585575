import * as React from "react";
import appHistory from "../../AppHistory";
import { DateTimeFormatter } from "../../Shared/Util/DateTimeFormatter";

interface IUpdateListItem {
  update: Api.INotificationViewModel
}

class UpdateListItem extends React.Component<IUpdateListItem> {
  constructor(props: IUpdateListItem) {
    super(props);

    this.handleRowClick = this.handleRowClick.bind(this);
  }

  public render() {
    return (
      <tr onClick={this.handleRowClick}>
        <td>{this.props.update.referenceId}</td>
        <td className='title-link'>{this.props.update.responseTitle}</td>
        <td>{this.props.update.responseCategory}</td>
        <td>{DateTimeFormatter.ToShortDate(this.props.update.dateCreated)}</td>
      </tr>
    )
  }

  private handleRowClick(e: any) {
    if (this.props.update.isExternalArtefact) {
      appHistory.push(`/manage/${this.props.update.responseId}?isExternal=true&updateNotification=true`);
    } else {
      appHistory.push(`/manage/${this.props.update.responseId}?documentId=${this.props.update.documentId}`);
    }
  }
}

export { UpdateListItem };
